<template>
  <div class="seo-component">
    <CContainer :fluid="true">
      <CRow>
        <CCol sm="12" md="6" class="seo-component-title">
          <CInput
            v-model="form.seo_title"
            label="Título"
            placeholder="Digite o título da página"
            class="mb-3"
            :maxlength="titleLength"
          />
          <div class="d-flex justify-content-between align-items-center">
            <CInputCheckbox
              label="Usar o mesmo nome/título desse registro"
              :inline="true"
              :custom="true"
              :checked="usePageTitleStatus"
              name="radioGroupName"
              @update:checked="handleUsePageTitle"
            />
            <small class="text-sm text-muted float-right">
              {{ form.seo_title ? form.seo_title.length : 0 }}/{{ titleLength }}
              caracteres
            </small>
          </div>
        </CCol>
        <CCol sm="12" md="6" class="seo-component-description">
          <CInput
            v-model="form.seo_description"
            label="Descrição"
            placeholder="Digite a descrição da página"
            class="mb-3"
            :maxlength="descriptionLength"
          />
          <div class="d-flex justify-content-between align-items-center">
            <CInputCheckbox
              label="Usar o mesmo texto/descrição desse registro"
              :inline="true"
              :custom="true"
              :checked="usePageDescriptionStatus"
              name="radioGroupName"
              @update:checked="handleUsePageDescription"
            />
            <small class="text-sm text-muted float-right">
              {{ form.seo_description ? form.seo_description.length : 0 }}/{{
                descriptionLength
              }}
              caracteres
            </small>
          </div>
        </CCol>
      </CRow>
      <CRow v-show="preview">
        <div class="gg-card mt-4">
          <cite class="gg-url"> https://www.example.com.br </cite>

          <h3 class="gg-title">
            {{ form.seo_title || 'Título da página' }}
          </h3>

          <span class="gg-desc">
            {{ form.seo_description || 'Descrição da página' }}
          </span>
        </div>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import striptags from 'striptags'
import * as entities from 'entities'

export default {
  props: {
    /**
     * SEO form fields
     */
    form: {
      type: Object,
      required: true
    },

    /**
     * Show preview
     */
    preview: {
      type: Boolean,
      default: true
    },

    /**
     * Is edit
     */
    isEdit: {
      type: Boolean,
      default: false
    },

    /**
     * Current page title
     */
    pageTitle: {
      type: String,
      default: ''
    },

    /**
     * Current page description
     */
    pageDescription: {
      type: String,
      default: ''
    },

    /**
     * Title max length
     */
    titleLength: {
      type: Number,
      default: 70
    },

    /**
     * Description max length
     */
    descriptionLength: {
      type: Number,
      default: 160
    }
  },

  data() {
    return {
      usePageTitleStatus: false,
      usePageDescriptionStatus: false
    }
  },

  watch: {
    pageTitle: {
      handler() {
        this.setTitle()
      }
    },

    pageDescription: {
      handler() {
        this.setDescription()
      }
    },

    'form.seo_title': {
      handler() {
        this.init()
      }
    },

    'form.seo_description': {
      handler() {
        this.init()
      }
    }
  },

  created() {
    if (!this.isEdit) {
      this.usePageTitleStatus = true
      this.usePageDescriptionStatus = true
    }
  },

  methods: {
    /**
     * Init SEO fields
     */
    init() {
      if (
        this.form.seo_title === this.truncate(this.pageTitle, this.titleLength)
      ) {
        this.usePageTitleStatus = true
        this.setTitle()
      } else {
        this.usePageTitleStatus = false
      }

      if (
        this.form.seo_description ===
        this.truncate(this.pageDescription, this.descriptionLength)
      ) {
        this.usePageDescriptionStatus = true
        this.setDescription()
      } else {
        this.usePageDescriptionStatus = false
      }
    },

    /**
     * Set SEO title
     */
    setTitle() {
      if (this.usePageTitleStatus) {
        this.$set(
          this.form,
          'seo_title',
          this.truncate(this.pageTitle, this.titleLength)
        )
      }
    },

    /**
     * Set SEO description
     */
    setDescription() {
      if (this.usePageDescriptionStatus) {
        this.$set(
          this.form,
          'seo_description',
          this.truncate(this.pageDescription, this.descriptionLength)
        )
      }
    },

    /**
     * Truncate and clean text
     */
    truncate(text, maxlength) {
      const strippedText = striptags(text)
      const strippedHtmlEntities = entities.decodeHTML(strippedText)
      const truncateText = strippedHtmlEntities.substring(0, maxlength)

      return truncateText
    },

    /**
     * If checked the seo title will use the form title property
     */
    handleUsePageTitle(checked) {
      if (checked) {
        this.usePageTitleStatus = true
        this.setTitle()
      } else {
        this.usePageTitleStatus = false
        this.$set(this.form, 'seo_title', '')
      }
    },

    /**
     * If checked the seo description will use the form text property
     */
    handleUsePageDescription(checked) {
      if (checked) {
        this.usePageDescriptionStatus = true
        this.setDescription()
      } else {
        this.usePageDescriptionStatus = false
        this.$set(this.form, 'seo_description', '')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gg-card {
  padding: 20px;
  -webkit-box-shadow: 0 0px 10px rgba(175, 175, 175, 0.3);
  box-shadow: 0 0px 10px rgba(175, 175, 175, 0.3);
  width: 100%;
  display: flex;
  flex-direction: column;

  .gg-title {
    color: #1a0dab;
    font-family: 'Arial', sans-serif;
    font-size: 1.5em;
    line-height: 26px;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 5px;
  }

  .gg-url {
    color: rgba(60, 64, 67, 0.81);
    display: block;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .gg-desc {
    font-family: 'Arial', sans-serif;
    font-size: 1em;
    display: block;
    line-height: 22px;
    color: #3c4043;
  }
}

.seo-component {
  .seo-component-title {
    padding-left: 0px;
  }

  .seo-component-description {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .seo-component {
    .seo-component-title {
      margin-bottom: 1rem !important;
    }

    .seo-component-title,
    .seo-component-description {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
