<template>
  <div id="styleguide">
    <CCard class="border-0">
      <!-- Title -->
      <CCardHeader>
        <h4 class="mb-0">Styleguide</h4>
      </CCardHeader>

      <CCardBody>
        <!-- Datatable -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Datatable</b>
              </CCardHeader>
              <CCardBody>
                <Datatable
                  :sorter="datatable.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/example/edit/"
                  @sorterValue="(event) => (datatable.sorter = event)"
                  @row-clicked="(event) => (datatable.rowCliked = event)"
                  @update:checked="updateStatus"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/example/edit/${id}`" />
                    <DatatableRemove
                      @row-clicked="(event) => (datatable.rowCliked = event)"
                      @deleted="() => {}"
                    />
                  </template>
                </Datatable>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/table.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Sortable -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Sortable</b>
              </CCardHeader>
              <CCardBody>
                <Sortable
                  :items="sortable.items"
                  :fields="sortable.fields"
                  :enable-switch-status="false"
                  :enable-export="false"
                  @endDragging="endDragging"
                >
                </Sortable>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://www.npmjs.com/package/vuedraggable"
                  target="_blank"
                >
                  <b>NPM</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Pagination -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Pagination</b>
              </CCardHeader>
              <CCardBody>
                <Pagination
                  :page="pagination.page"
                  :per-page="pagination.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="paginationUpdate"
                  @update:perpage="paginationPerpageUpdate"
                />
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/pagination.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Breadcrumb -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Breadcrumb</b>
              </CCardHeader>
              <CCardBody>
                <CBreadcrumbRouter class="border-0 mb-0" />
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/breadcrumb.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- SEO -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">SEO</b>
              </CCardHeader>
              <CCardBody>
                <Seo
                  :form="seo"
                  page-title="Título da página"
                  page-description="Descrição da página"
                  :is-edit="false"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <!-- Datepicker -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Datepicker</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12" md="4" lg="3">
                    <Datepicker
                      :value="datepicker.value"
                      @selected="datepickerSelected"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://www.npmjs.com/package/vuejs-datepicker"
                  target="_blank"
                >
                  <b>NPM</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Search -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Search</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol col="12">
                    <Search :value="searchText" @search="handleSearch" />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/form-components.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Selects -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">
                  Single Select / Multiple Select / Select Ajax
                </b>
              </CCardHeader>

              <CCardBody style="padding-bottom: 0px">
                <CRow>
                  <!-- Single select -->
                  <CCol sm="12" md="4">
                    <div class="form-group">
                      <label for="single-select">Single select</label>
                      <Multiselect
                        id="single-select"
                        :value="
                          selectOptions.find(
                            (option) => option.value === selectSingleValue
                          )
                        "
                        :options="selectOptions"
                        @select="
                          (selected) => (selectSingleValue = selected.value)
                        "
                      />
                    </div>
                  </CCol>

                  <!-- Multiple select -->
                  <CCol sm="12" md="4">
                    <div class="form-group">
                      <label for="multiple-select">Multiple select</label>
                      <Multiselect
                        id="multiple-select"
                        :value="
                          selectOptions.filter((option) =>
                            selectMultipleValue.includes(option.value)
                          )
                        "
                        :options="selectOptions"
                        @select="
                          (selected) => selectMultipleValue.push(selected.value)
                        "
                        @remove="
                          (removed) =>
                            selectMultipleValue.splice(
                              selectMultipleValue.indexOf(removed.value),
                              1
                            )
                        "
                        :searchable="true"
                        :multiple="true"
                      >
                        <span slot="noResult">Nenhum resultado encontrado</span>
                      </Multiselect>
                    </div>
                  </CCol>

                  <!-- Ajax select -->
                  <CCol sm="12" md="4">
                    <div class="form-group">
                      <label for="ajax-select">Ajax select</label>
                      <Multiselect
                        id="ajax-select"
                        :value="
                          selectOptionsAjax.filter((option) =>
                            selectAjaxValue.includes(option.id)
                          )
                        "
                        :options="selectOptionsAjax"
                        @select="
                          (selected) => selectAjaxValue.push(selected.id)
                        "
                        @remove="
                          (removed) =>
                            selectAjaxValue.splice(
                              selectAjaxValue.indexOf(removed.id),
                              1
                            )
                        "
                        :searchable="true"
                        :multiple="true"
                        label="name"
                        track-by="id"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>

              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://www.npmjs.com/package/vue-multiselect"
                  target="_blank"
                >
                  <b>NPM</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Text Editor -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Text Editor</b>
              </CCardHeader>
              <CCardBody>
                <TextEditor v-model="textEditor.value" />
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://www.npmjs.com/package/@tinymce/tinymce-vue"
                  target="_blank"
                >
                  <b>NPM</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- List Item -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">List Item</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol col="12">
                    <ListItem
                      :items="listItem.items"
                      :fields="listItem.fields"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <!-- Link Gallery -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Link Gallery</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol col="12">
                    <LinkGallery :items="linkGallery.items" />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <!-- Card Image -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Card Image</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12" md="6" lg="4" xl="3">
                    <CardImage
                      :img-url="cardImage.imgUrl"
                      :title="cardImage.title"
                      :description="cardImage.description"
                      :status="cardImage.status"
                      :actions-url="cardImage.actionsUrl"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/card-components.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- Card Log -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">Card Log</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CardLog :log="log" />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://coreui.io/vue/docs/components/card-components.html"
                  target="_blank"
                >
                  <b>CoreUI</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>

        <!-- File Uploader -->
        <CRow>
          <CCol sm="12">
            <CCard accent-color="info">
              <CCardHeader>
                <b class="text-uppercase component-title">File Uploader</b>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol lg="12">
                    <Fileuploader
                      id="medias"
                      :max-files="10"
                      :min-cropped-width="550"
                      :min-cropped-height="456"
                      :enable-multiple="true"
                      :medias="fileUploader.medias"
                      :accept-types="fileUploader.acceptTypes"
                      :extensions="fileUploader.extensions"
                      :uploader-info="fileUploader"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                Find the documentation for this component at
                <a
                  href="https://lian-yue.github.io/vue-upload-component/#/en/documents"
                  target="_blank"
                >
                  <b>NPM</b>
                </a>
                to see the library info.
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import Datatable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import Sortable from '@/components/ui/Sortable'
import Pagination from '@/components/ui/Pagination'
import Seo from '@/components/ui/Seo'
import Datepicker from '@/components/ui/Datepicker'
import Search from '@/components/ui/Search'
import Multiselect from '@/components/ui/Multiselect'
import TextEditor from '@/components/ui/TextEditor'
import ListItem from '@/components/ui/ListItem'
import LinkGallery from '@/components/ui/LinkGallery'
import CardImage from '@/components/ui/CardImage'
import CardLog from '@/components/ui/CardLog'
import Fileuploader from '@/components/ui/Fileuploader'
import moment from 'moment'
import axios from 'axios'

export default {
  metaInfo: {
    title: 'Styleguide',
    titleTemplate: '%s - OW Interactive'
  },

  components: {
    Datatable,
    DatatableEdit,
    DatatableRemove,
    Sortable,
    Pagination,
    Seo,
    Datepicker,
    Search,
    Multiselect,
    TextEditor,
    ListItem,
    LinkGallery,
    CardImage,
    CardLog,
    Fileuploader
  },

  data() {
    return {
      datatable: {
        items: [
          {
            id: 1,
            username: 'Samppa Nori',
            registered: '2012/01/01',
            role: {
              name: 'Membro'
            },
            status: true
          },
          {
            id: 2,
            username: 'Estavan Lykos',
            registered: '2012/02/01',
            role: {
              name: 'Equipe'
            },
            status: false
          },
          {
            id: 3,
            username: 'Chetan Mohamed',
            registered: '2012/02/01',
            role: {
              name: 'Administrador'
            },
            status: true
          },
          {
            id: 4,
            username: 'Derick Maximinus',
            registered: '2012/03/01',
            role: {
              name: 'Membro'
            },
            status: false
          },
          {
            id: 5,
            username: 'Friderik Dávid',
            registered: '2012/01/21',
            role: {
              name: 'Equipe'
            },
            status: true
          }
        ],
        fields: [
          {
            key: 'status',
            _style: 'width: 10%'
          },
          {
            key: 'username',
            label: 'Nome',
            _style: 'width: 30%'
          },
          {
            key: 'registered',
            label: 'Data de registro',
            _style: 'width: 20%'
          },
          {
            key: 'role.name',
            label: 'Permissão',
            _style: 'width: 20%'
          },
          {
            key: 'actions',
            label: 'Ações',
            _style: 'width: 10%',
            sorter: false,
            filter: false
          }
        ],
        sorter: {},
        rowCliked: ''
      },
      sortable: {
        items: [
          {
            id: 1,
            username: 'Samppa Nori',
            registered: '2012/01/01',
            role: {
              name: 'Membro'
            },
            status: 1
          },
          {
            id: 2,
            username: 'Estavan Lykos',
            registered: '2012/02/01',
            role: {
              name: 'Equipe'
            },
            status: 1
          },
          {
            id: 3,
            username: 'Chetan Mohamed',
            registered: '2012/02/01',
            role: {
              name: 'Administrador'
            },
            status: 0
          },
          {
            id: 4,
            username: 'Derick Maximinus',
            registered: '2012/03/01',
            role: {
              name: 'Membro'
            },
            status: 1
          },
          {
            id: 5,
            username: 'Friderik Dávid',
            registered: '2012/01/21',
            role: {
              name: 'Equipe'
            },
            status: 0
          }
        ],
        fields: [
          {
            key: 'status',
            label: 'Status',
            _style: 'width: 20%'
          },
          {
            key: 'username',
            label: 'Nome',
            _style: 'width: 30%'
          },
          {
            key: 'registered',
            label: 'Data de registro',
            _style: 'width: 20%'
          },
          {
            key: 'role.name',
            label: 'Permissão',
            _style: 'width: 20%'
          }
        ],
        sorter: {},
        rowCliked: ''
      },
      pagination: {
        page: 1,
        perpage: 10,
        pages: 10,
        total: 100,
        items: 10
      },
      seo: {
        title: '',
        description: ''
      },
      datepicker: {
        value: moment().toDate()
      },
      searchText: '',
      selectSingleValue: null,
      selectMultipleValue: [],
      selectAjaxValue: [],
      selectOptions: [
        {
          value: 1,
          label: 'Opção 1'
        },
        {
          value: 2,
          label: 'Opção 2'
        },
        {
          value: 3,
          label: 'Opção 3'
        }
      ],
      selectOptionsAjax: [],
      textEditor: {
        value: ''
      },
      listItem: {
        items: [],
        fields: [
          {
            key: 'username',
            label: 'Nome',
            type: 'text',
            placeholder: 'Digite seu nome',
            required: true,
            disabled: false,
            _classes: 'col-sm-12 col-md-5 col-lg-5',
            _style: ''
          },
          {
            key: 'email',
            label: 'E-mail',
            type: 'email',
            required: true,
            disabled: false,
            placeholder: 'Digite seu e-mail',
            _classes: 'col-sm-12 col-md-5 col-lg-5',
            _style: ''
          }
        ]
      },
      linkGallery: {
        items: []
      },
      cardImage: {
        imgUrl: 'https://picsum.photos/1024/480/?image=54',
        title: 'Título de exemplo',
        description:
          'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem, repellat eligendi! Delectus, aliquam tempore fugiat ullam ad esse saepe! Nobis, neque. Unde iste eum modi ullam hic, amet velit delectus.',
        status: true,
        actionsUrl: 'https://example.com/users/1'
      },
      log: {
        created_by: 'John Doe',
        created_at: '20/09/2020 07:00',
        updated_by: 'Maria Doe',
        updated_at: '20/09/2020 07:30'
      },
      fileUploader: {
        label: 'Galeria de Arquivos',
        extensions: 'pdf,png,jpeg,jpg,docx,zip',
        acceptTypes:
          'application/msword,application/pdf,image/png,image/jpeg,application/zip, image/jpg',
        medias: []
      }
    }
  },

  created() {
    this.getSelectAjaxOptions()
  },

  mounted() {
    const loader = this.$loading.show()

    setTimeout(() => {
      loader.hide()
    }, 3000)
  },

  methods: {
    updateStatus(event) {
      console.log(event)
    },

    endDragging: debounce(function (items) {
      this.sortable.items = items
      this.$notify({
        group: 'notifications',
        type: 'success',
        text: 'Ordenação alterada com sucesso.'
      })
    }, 1000),

    paginationUpdate(page) {
      this.pagination.page = page
    },

    paginationPerpageUpdate(perpage) {
      console.log(perpage)
    },

    datepickerSelected(date) {
      this.datepicker.value = moment(date).toDate()
    },

    handleSearch(value) {
      console.log(value)
    },

    async getSelectAjaxOptions() {
      try {
        const { data } = await axios.get(
          'https://jsonplaceholder.typicode.com/users'
        )
        this.selectOptionsAjax = data
        this.selectAjaxValue = [1]
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
#styleguide {
  .component-title {
    letter-spacing: 2px;
    font-size: 0.8em;
  }

  .breadcrumb {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
  }
}
</style>
