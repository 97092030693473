<template>
  <div class="list-item">
    <!-- Remove modal -->
    <CModal
      title="Remover"
      color="danger"
      :show.sync="modal.show"
      :centered="true"
    >
      Ao confirmar essa ação o item não poderá ser recuperado, tem certeza que
      desejar continuar?
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="toggleRemoveModal"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="remove">
          Remover
        </button>
      </template>
    </CModal>

    <!-- Form with the input fields -->
    <form @submit.prevent="add">
      <CRow>
        <CCol
          v-for="(field, fieldIndex) in fields"
          :key="`field-${fieldIndex}`"
          :class="field._classes"
          :style="field._style"
          col="12"
        >
          <CSelect
            v-if="field.type === 'select'"
            v-model="form[field.key]"
            :label="field.label"
            :required="field.required"
            :disabled="field.disabled"
            :options="field.options"
            :placeholder="field.placeholder"
          >
          </CSelect>
          <div v-else-if="field.type === 'currency'">
            <label>{{ field.label }}</label>
            <money
              v-model="form[field.key]"
              class="form-control"
              :required="field.required"
              :disabled="field.disabled"
            ></money>
          </div>
          <CInput
            v-else-if="field.mask"
            v-model="form[field.key]"
            v-mask="field.mask"
            :label="field.label"
            :type="field.type"
            :required="field.required"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
          />
          <CInput
            v-else
            v-model="form[field.key]"
            :label="field.label"
            :type="field.type"
            :required="field.required"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
          />
        </CCol>
        <CCol col="12" sm="12" md="2" class="list-item-submit">
          <CButton color="link" type="submit" class="p-0 h-100 mt-2">
            ADICIONAR
          </CButton>
        </CCol>
      </CRow>
    </form>

    <!-- List with the items -->
    <div v-if="items.length > 0" class="mt-2 mb-4 list-item-items">
      <label for="">
        <b>{{ itemsName }} adicionados</b>
      </label>
      <CListGroup>
        <draggable
          :list="items"
          :disabled="currentEditIndex !== null"
          @end="handleEnd"
        >
          <CListGroupItem
            v-for="(item, itemIndex) in items"
            :key="`item-${itemIndex}`"
          >
            <form @submit.prevent="save(itemIndex)">
              <CRow class="align-items-center">
                <CCol
                  v-for="(field, fieldIndex) in fields"
                  :key="`field-${fieldIndex}-${itemIndex}`"
                  class="list-item-field"
                  :class="field._classes"
                  :style="field._style"
                  col="12"
                >
                  <div class="d-flex align-items-center">
                    <small
                      for=""
                      class="text-muted pr-1"
                      style="padding-top: 1px"
                    >
                      <b>{{ field.label }} </b>
                    </small>
                    <CSelect
                      v-if="field.type === 'select'"
                      v-model="item[field.key]"
                      :required="field.required"
                      :disabled="field.disabled"
                      :options="field.options"
                      :placeholder="'-'"
                      :readonly="itemIndex !== currentEditIndex"
                      size="sm"
                      class="flex-grow-1"
                    >
                    </CSelect>
                    <money
                      v-else-if="field.type === 'currency'"
                      v-model="item[field.key]"
                      class="flex-grow-1 form-control form-control-sm"
                      :required="field.required"
                      :disabled="field.disabled"
                      :readonly="itemIndex !== currentEditIndex"
                    ></money>
                    <CInput
                      v-else-if="field.mask"
                      v-model="item[field.key]"
                      v-facade="field.mask"
                      :type="field.type"
                      :required="field.required"
                      :disabled="field.disabled"
                      :placeholder="'-'"
                      :readonly="itemIndex !== currentEditIndex"
                      size="sm"
                      class="flex-grow-1"
                    />
                    <CInput
                      v-else
                      v-model="item[field.key]"
                      :type="field.type"
                      :required="field.required"
                      :disabled="field.disabled"
                      :placeholder="'-'"
                      :readonly="itemIndex !== currentEditIndex"
                      size="sm"
                      class="flex-grow-1"
                    />
                  </div>
                </CCol>
                <CCol col="12" sm="12" md="2" class="list-item-submit">
                  <div class="table-actions">
                    <div class="table-action">
                      <router-link
                        v-if="editOnNewPage && item.id"
                        class="button"
                        :to="`${editUrlPage}/${item.id}`"
                      >
                        <CIcon
                          v-c-tooltip="'Editar'"
                          name="cil-pencil"
                          placement="top"
                        />
                      </router-link>
                      <button
                        v-show="
                          itemIndex !== currentEditIndex && !editOnNewPage
                        "
                        type="button"
                        @click="edit(itemIndex)"
                      >
                        <CIcon
                          v-c-tooltip="'Editar'"
                          name="cil-pencil"
                          placement="top"
                        >
                        </CIcon>
                      </button>
                      <button
                        v-show="itemIndex === currentEditIndex"
                        type="submit"
                      >
                        <CIcon
                          v-c-tooltip="'Salvar'"
                          name="cil-save"
                          placement="top"
                        >
                        </CIcon>
                      </button>
                    </div>
                    <div class="table-action">
                      <button
                        type="button"
                        @click="toggleRemoveModal(itemIndex)"
                      >
                        <CIcon
                          v-c-tooltip="'Remover'"
                          name="cil-trash"
                          placement="top"
                        >
                        </CIcon>
                      </button>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </form>
          </CListGroupItem>
        </draggable>
      </CListGroup>
    </div>
  </div>
</template>

<script>
import { get, set } from 'lodash'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },

  props: {
    /**
     * Array of objects, where each object represents one row in table.
     */
    items: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Switch between edit on new page or
     * in the list itself
     */
    editOnNewPage: {
      type: Boolean,
      default: false
    },

    /**
     * Path to edit page
     * ex.: 'compliance', will generate 'compliance/12'
     * omit slash bar (/) will get the current url
     * and add the editUrlPage prop to it
     */
    editUrlPage: {
      type: String,
      default: ''
    },

    /**
     * Prop for table columns configuration
     * Field object configuration:
     * @arg key @type {String} input key equal to item key
     * @arg label @type {String} label of input
     * @arg type @type {String} the input type (text, select, email, password, [...])
     * @arg required @type {Boolean} input required attribute
     * @arg disabled @type {Boolean} input disabled attribute
     * @arg placeholder @type {String} input placeholder attribute
     * @arg options @type {Array} Select options, ex: [{ value: 'js', label: 'JavaScript' }]
     * @arg mask @type {String, Array} Mask for input (use: https://www.npmjs.com/package/v-mask)
     * @arg _classes @type {String, Array, Object} adds classes to column
     * @arg _style @type {String, Array, Object} adds styles to the column
     */
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Items list name for the title
     */
    itemsName: {
      type: String,
      default: 'Itens'
    }
  },

  data() {
    return {
      currentEditIndex: null,
      currentRemoveIndex: null,
      form: {},
      empty: {},
      modal: {
        show: false
      }
    }
  },

  created() {
    this.setFormFields()
  },

  methods: {
    /**
     * Get method from lodash
     * @see https://lodash.com/docs/#get
     * @public
     */
    get,

    /**
     * onEnd function is triggered when the user finishes dragging the item
     * @param {Event} event Draggable event
     * @public
     */
    handleEnd(event) {
      this.$emit('updated', event)
    },

    /**
     * Show confirm modal
     * @public
     */
    toggleRemoveModal(index) {
      this.currentRemoveIndex = index
      this.modal.show = !this.modal.show
    },

    /**
     * Set form fields
     * @public
     */
    setFormFields() {
      this.fields.forEach((field) => {
        this.$set(this.form, field.key, '')
      })
    },

    /**
     * Add new item
     * @public
     */
    add() {
      const item = {}

      this.fields.map((field) => {
        const key = field.key
        const value = this.form[key]

        set(item, key, value)
      })

      this.items.push(item)
      this.setFormFields()
    },

    /**
     * Edit an item
     * @public
     */
    edit(index) {
      this.currentEditIndex = index
    },

    /**
     * Save an item
     * @public
     */
    // eslint-disable-next-line no-unused-vars
    save(index) {
      this.currentEditIndex = null
    },

    /**
     * Remove an item
     * @public
     */
    remove() {
      this.items.splice(this.currentRemoveIndex, 1)
      this.currentRemoveIndex = null
      this.modal.show = false
    }
  }
}
</script>

<style lang="scss">
.list-item {
  .list-group-item {
    cursor: move;

    &.sortable-ghost {
      color: #4f5d73;
      background-color: rgba(0, 0, 21, 0.075);
    }
  }

  .table-actions {
    display: flex;

    .table-action {
      padding-right: 20px;

      &:last-child {
        padding-right: 0px;
      }

      button,
      .button {
        color: inherit;
        background: transparent;
        border: none;
        padding: 0px;
      }
    }
  }

  .form-control[readonly] {
    background-color: transparent;
    border: none;
    cursor: move;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    line-height: 23px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .list-item-items {
    .form-group {
      margin-bottom: 0px;
    }
  }
}

// .list-item-submit {
//   button {
//     margin-top: 5px;
//   }
// }

@media only screen and (max-width: 992px) {
  .list-item-submit {
    text-align: center;

    button {
      margin-top: 0px;
    }

    .table-actions {
      justify-content: center;
    }
  }

  .list-item-items {
    .list-group-item {
      padding: 1rem 1.25rem;

      .list-item-field {
        margin-top: 1rem !important;

        &:first-child {
          margin-top: 0px !important;
        }

        small {
          min-width: 50px;
        }
      }
    }
  }
}
</style>
