<template>
  <div>
    <!-- Remove modal -->
    <CModal
      title="Remover"
      color="danger"
      :centered="true"
      :show.sync="modal.show"
    >
      Ao confirmar essa ação o item não poderá ser recuperado, tem certeza que
      desejar continuar?
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="remove">
          Remover
        </button>
      </template>
    </CModal>

    <!-- Card -->
    <CCard class="card-image">
      <CCardHeader v-if="imgUrl" class="p-0">
        <CCardImg :src="imgUrl" align="center" :fluid="true" :fluid-grow="true">
        </CCardImg>
      </CCardHeader>
      <CCardBody>
        <CCardTitle v-if="title" :class="{ 'mb-0': !description }">
          {{ title }}
          <CBadge
            v-if="showStatus"
            :color="status ? 'success' : 'secondary'"
            class="float-right"
            shape="pill"
          >
            <small>{{ status ? 'Ativo' : 'Inativo' }}</small>
          </CBadge>
        </CCardTitle>
        <CCardText v-if="description">
          {{ description }}
        </CCardText>
      </CCardBody>
      <CCardFooter v-if="showActions">
        <a
          v-if="showEditAction"
          :href="actionsUrl"
          class="text-primary d-flex align-items-center mb-3"
        >
          <CIcon name="cil-pencil" class="mr-2" />
          <b>Editar</b>
        </a>
        <a
          v-if="showRemoveAction"
          href="javascript:void(0)"
          class="text-danger d-flex align-items-center"
          @click="toggleModal"
        >
          <CIcon name="cil-trash" class="mr-2" />
          <b>Remover</b>
        </a>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Source of the image
     */
    imgUrl: {
      type: String,
      required: true
    },

    /**
     * Title of the card
     */
    title: {
      type: String,
      required: true
    },

    /**
     * Description of the card
     */
    description: {
      type: String,
      default: ''
    },

    /**
     * Show status badge on header
     */
    showStatus: {
      type: Boolean,
      default: true
    },

    /**
     * Status of the card
     */
    status: {
      type: Boolean,
      default: true
    },

    /**
     * Show actions on footer
     */
    showActions: {
      type: Boolean,
      default: true
    },

    /**
     * Show edit action on footer
     */
    showEditAction: {
      type: Boolean,
      default: true
    },

    /**
     * Show remove action on footer
     */
    showRemoveAction: {
      type: Boolean,
      default: true
    },

    /**
     * Actions URL for edit/remove event
     */
    actionsUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      modal: {
        show: false
      }
    }
  },

  methods: {
    /**
     * Show confirm modal
     * @public
     */
    toggleModal() {
      this.modal.show = !this.modal.show
    },

    /**
     * Send removed item to API
     * @public
     */
    remove() {
      this.toggleModal()

      alert('Remove function not implemented!')
    }
  }
}
</script>

<style lang="scss">
.card {
  &.card-image {
    img {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
</style>
