<template>
  <div>
    <ListItem :items="items" :fields="fields" />
  </div>
</template>

<script>
import ListItem from '@/components/ui/ListItem'

export default {
  components: {
    ListItem
  },

  props: {
    /**
     * Array of objects, where each object represents one row in table.
     */
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      fields: [
        {
          key: 'text',
          label: 'Texto',
          type: 'text',
          placeholder: 'Digite o texto',
          required: true,
          disabled: false,
          _classes: 'col-md-3 col-lg-4',
          _style: ''
        },
        {
          key: 'url',
          label: 'URL',
          type: 'url',
          required: true,
          disabled: false,
          placeholder: 'https://www.example.com.br',
          _classes: 'col-md-4 col-lg-4',
          _style: ''
        },
        {
          key: 'target',
          label: 'Abrir em',
          type: 'select',
          required: true,
          disabled: false,
          placeholder: '',
          options: [
            {
              label: 'Mesma página',
              value: '_self'
            },
            {
              label: 'Nova página',
              value: '_blank'
            }
          ],
          _classes: 'col-md-3 col-lg-2',
          _style: ''
        }
      ]
    }
  }
}
</script>

<style lang="scss"></style>
