<template>
  <div id="fileuploader">
    <!-- Edit modal -->
    <EditModal
      :file="editModal.file"
      :show="editModal.displayModal"
      @onCloseModal="saveEditmodal"
      @onEditFileSaved="updateFileInfo"
    />

    <!-- items to upload -->
    <div>
      <label class="uploaderInfo mb-3">
        {{ uploaderInfo.label }}
        <span v-html="uploaderDescription" />
      </label>

      <!-- drag and drop table -->
      <div v-if="uploadFile" class="mb-2">
        <div class="help-message">
          <CIcon size="xl" name="cil-cloud-upload" class="mb-2" />
          <h5 class="mb-0">Arraste e solte seus arquivos aqui</h5>
        </div>
      </div>

      <CDataTable
        v-if="files.length > 0"
        :items="files"
        :fields="fields"
        hover
        striped
        outlined
      >
        <template #name="{ item }">
          <td class="d-flex align-items-center">
            <img
              v-if="item.thumb"
              width="40"
              height="40"
              :src="item.thumb"
              alt="thumb"
              class="mr-2 thumb"
            />
            {{ item.name }}
          </td>
        </template>
        <template #size="{ item }">
          <td>{{ formatSize(item.size) }}</td>
        </template>
        <template #status="{ item }">
          <td>
            <div
              v-if="item.active || item.progress !== '0.00'"
              class="progress"
            >
              <div
                :class="{
                  'progress-bar': true,
                  'progress-bar-striped': true,
                  'bg-danger': item.error,
                  'progress-bar-animated': item.active
                }"
                role="progressbar"
                :style="{ width: item.progress + '%' }"
              >
                {{ item.progress }}%
              </div>
            </div>
            <div v-else>N\D</div>
          </td>
        </template>
        <template #actions="{ item }">
          <td class="dataTable-actions">
            <RemoveAction
              v-if="enableRemoveOnUploader"
              class="action"
              @remove-file="removeFile(false, item)"
            />

            <EditAction
              v-if="enableEditOnUploader"
              class="action"
              :file="item"
              :uploader-instance="$refs[id]"
            />

            <SeeAction
              v-if="enableSeeOnUploader"
              class="action"
              :url="item.blob"
              :mime-type="item.type"
            />
            <DownloadAction
              v-if="enableDownloaderOnUploader"
              class="action"
              :file="item"
            />
            <CropperAction
              v-if="
                enableCropOnUploader &&
                item.type &&
                item.type.split('/')[0] == 'image'
              "
              class="action"
              :file="item"
              :uploader-instance="$refs[id]"
              :min-cropped-width="minCroppedWidth"
              :min-cropped-height="minCroppedHeight"
            />
          </td>
        </template>
        <template #no-items-view>
          <div class="text-center">
            Nenhum registro foi encontrado! Verifique os dados da sua pesquisa
            ou crie um novo registro.
          </div>
        </template>
      </CDataTable>
    </div>

    <!-- file uploader -->
    <div
      v-if="files.length > 0 || uploadFile"
      class="w-100 d-flex justify-content-between justify-content-sm-end mb-3"
    >
      <file-upload
        :ref="id"
        v-model="files"
        :name="name"
        class="btn btn-primary d-flex align-items-center cursor-pointer"
        :class="uploadFile ? '' : 'invisible'"
        :headers="{ Authorization: getAuthorization }"
        :post-action="getApiUrl + postAction"
        :extensions="extensions"
        :input-id="id"
        :multiple="enableMultiple"
        :drop="enableDragAndDrop"
        :drop-directory="enableDropDirectory"
        :accept="acceptTypes"
        :size="sizeFile"
        :maximum="maxFiles"
        :data="data"
        @input-file="inputFile"
        @input-filter="inputFilter"
      >
        {{ selectButtonText }}
        <CIcon name="cil-folder-open" class="ml-2 d-none d-sm-block" />
      </file-upload>

      <CButton
        v-if="files.length > 0"
        color="success"
        class="d-flex align-items-center ml-3"
        @click="$refs[id].active = true"
      >
        {{ uploadButtonText }}
        <CIcon name="cil-cloud-upload" class="ml-2 d-none d-sm-block" />
      </CButton>
    </div>

    <div v-if="medias.length > 0" class="position-relative table-responsive">
      <table
        class="data-from-api-table table table-striped table-hover border w-100"
      >
        <thead v-if="fields.length > 0" class="table-head">
          <th class="table-head-drag" style="width: 5%"></th>
          <th
            v-for="(field, fieldIndex) in fields"
            :key="fieldIndex"
            :style="field._style"
          >
            {{ field.label }}
          </th>
        </thead>
        <draggable :list="medias" tag="tbody">
          <tr v-for="(data, index) in medias" :key="index">
            <td>
              <CIcon name="cil-menu" class="mr-3" />
            </td>
            <template v-for="(field, fieldIndex) in fields">
              <td
                v-if="field.key == 'actions'"
                :key="fieldIndex"
                :style="field._style"
                class="dataTable-actions"
              >
                <RemoveAction
                  v-if="enableRemoveOnApiTable"
                  class="action"
                  @remove-file="removeFile(true, data)"
                />

                <EditAction
                  v-if="enableEditOnApiTable"
                  class="action"
                  :file="data"
                  @onEdit="editModal = $event"
                />

                <SeeAction
                  v-if="
                    enableSeeOnApiTable &&
                    data.mime_type &&
                    data.mime_type.split('/')[0] == 'image'
                  "
                  class="action"
                  :url="data.url"
                  :mime-type="data.mime_type"
                />

                <DownloadAction
                  v-if="enableDownloaderOnApiTable"
                  class="action"
                  :file="data"
                />

                <CropperAction
                  v-if="enableCropOnApiTable"
                  class="action"
                  :file="data"
                />
              </td>
              <td
                v-else-if="field.key == 'name'"
                :key="fieldIndex"
                :style="field._style"
                class="d-flex align-items-center"
              >
                <img
                  v-if="data.mime_type.split('/')[0] == 'image'"
                  width="40"
                  height="40"
                  :src="data.url"
                  alt="thumb"
                  class="mr-2 thumb"
                />
                {{
                  get(medias[index].custom, 'title') ||
                  get(medias[index], field.key)
                }}
              </td>
              <td
                v-else-if="field.key == 'size'"
                :key="fieldIndex"
                :style="field._style"
              >
                {{ formatSize(get(medias[index], field.key)) }}
              </td>

              <td
                v-else-if="field.key == 'created_at'"
                :key="fieldIndex"
                :style="field._style"
              >
                {{ get(medias[index], field.key) | moment() }}
              </td>

              <td
                v-else-if="field.key == 'status'"
                :key="fieldIndex"
                :style="field._style"
              >
                Finalizado
              </td>

              <td v-else :key="fieldIndex" :style="field._style">
                {{ get(medias[index], field.key) }}
              </td>
            </template>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
// dependences
import moment from 'moment'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import { get, findIndex } from 'lodash'
import draggable from 'vuedraggable'

// components
import CropperAction from '@/components/ui/FileUploaderComponents/Actions/Cropper'
import SeeAction from '@/components/ui/FileUploaderComponents/Actions/See'
import DownloadAction from '@/components/ui/FileUploaderComponents/Actions/Download'
import EditAction from '@/components/ui/FileUploaderComponents/Actions/Edit'
import RemoveAction from '@/components/ui/FileUploaderComponents/Actions/Remove'
import EditModal from '@/components/ui/FileUploaderComponents/Modals/EditModal'
import AuthService from '@/services/auth.service'

export default {
  components: {
    FileUpload,
    draggable,
    CropperAction,
    SeeAction,
    DownloadAction,
    EditAction,
    RemoveAction,
    EditModal
  },

  filters: {
    moment: function (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },

  props: {
    /**
     * Uploader id
     */
    id: {
      type: String,
      required: true
    },

    /**
     * Information about the uploader
     */
    uploaderInfo: {
      type: Object,
      default: () => {
        return {
          label: 'Arquivos/Imagens'
        }
      }
    },

    /**
     * Determines the type of uploader
     */
    uploaderType: {
      type: String,
      default: ''
    },

    /**
     * Prop for table columns configuration. If prop is not defined, table will display columns based on the first item keys, omitting keys that begins with underscore (e.g. '_classes')
     */
    fields: {
      type: Array,
      default: () => {
        return [
          {
            key: 'name',
            label: 'Nome'
          },
          {
            key: 'size',
            label: 'Tamanho'
          },
          {
            key: 'status',
            label: 'Status'
          },
          {
            key: 'created_at',
            label: 'Criado em',
            _classes: 'd-none'
          },
          {
            key: 'actions',
            label: 'Ações'
          }
        ]
      }
    },

    /**
     * The accept attribute of the input tag, MIME type
     */
    acceptTypes: {
      type: String,
      default: 'image/*,video/*,application/*'
    },

    /**
     * The accept max file size
     */
    sizeFile: {
      type: Number,
      default: 1024 * 1024 * 15
    },

    /**
     * Allow upload file extensions
     */
    extensions: {
      type: String,
      default: undefined
    },

    /**
     * Max number of files to be uploaded
     */
    maxFiles: {
      type: Number,
      default: 1
    },

    /**
     * The multiple attribute of the input tag Whether to allow multiple files to be selected
     */
    enableMultiple: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to allow drag and drop
     */
    enableDragAndDrop: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to open the drag directory
     */
    enableDropDirectory: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag contain files saved on database
     */
    medias: {
      type: Array,
      default: () => []
    },

    /**
     * Enable table export to json, csv and xls
     */
    enableExport: {
      type: Boolean,
      default: true
    },

    /**
     * The minimum width of the crop box.
     */
    minCroppedWidth: {
      type: Number,
      default: 0
    },

    /**
     * The minimum Height of the crop box.
     */
    minCroppedHeight: {
      type: Number,
      default: 0
    },

    /**
     * The exact height that the image should have
     * if zero, heigth is not validated
     */
    height: {
      type: Number,
      default: 0
    },

    /**
     * The exact width that the image should have
     * if zero, width is not validated
     */
    width: {
      type: Number,
      default: 0
    },

    /**
     * The maxm height that the image should have
     * if zero, width is not validated
     */
    maxHeight: {
      type: Number,
      default: 0
    },

    /**
     * The maxm width that the image should have
     * if zero, width is not validated
     */
    maxWidth: {
      type: Number,
      default: 0
    },

    /**
     * Attribute tag whether to display Cropper action on uploader table
     */
    enableCropOnUploader: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to display Downloader action on uploader table
     */
    enableDownloaderOnUploader: {
      type: Boolean,
      default: false
    },

    /**
     * Attribute tag whether to display Edit action on uploader table
     */
    enableEditOnUploader: {
      type: Boolean,
      default: false
    },

    /**
     * Attribute tag whether to display See action on uploader table
     */
    enableSeeOnUploader: {
      type: Boolean,
      default: false
    },

    /**
     * Attribute tag whether to display Remove action on uploader table
     */
    enableRemoveOnUploader: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to display Cropper action on API table
     */
    enableCropOnApiTable: {
      type: Boolean,
      default: false
    },

    /**
     * Attribute tag whether to display Downloader action on API table
     */
    enableDownloaderOnApiTable: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to display Edit action on API table
     */
    enableEditOnApiTable: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to display See action on API table
     */
    enableSeeOnApiTable: {
      type: Boolean,
      default: true
    },

    /**
     * Attribute tag whether to display Remove action on API table
     */
    enableRemoveOnApiTable: {
      type: Boolean,
      default: true
    },

    /**
     * Text displayed on submit button
     */
    uploadButtonText: {
      type: String,
      default: 'Iniciar Upload'
    },

    /**
     * Text displayed button that select files
     */
    selectButtonText: {
      type: String,
      default: 'Selecionar Arquivos'
    },

    /**
     *  Endpoint address to do a post request
     */
    postAction: {
      type: String,
      default: '/media'
    },

    /**
     *  Endpoint address to do a put request
     */
    putAction: {
      type: String,
      default: ''
    },

    /**
     * Submit options
     */
    submitOptions: {
      type: Object,
      default: () => {}
    },

    name: {
      type: String,
      default: 'media'
    },

    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      files: [],
      editFile: {
        show: false
      },
      editModal: {
        file: {},
        displayModal: false
      },
      uploadFile: true
    }
  },

  computed: {
    uploaderDescription() {
      const height = this.height
      const width = this.width
      const maxHeight = this.maxHeight
      const maxWidth = this.maxWidth
      const extensions = this.extensions
      const maxFileSize = this.formatSize(this.sizeFile)
      const cropWidth = this.minCroppedWidth
      const cropHeight = this.minCroppedHeight
      const { recomendation } = this.uploaderInfo
      let description = ''

      const dimensionsDescription = this.returnsDimensionsDescription(
        width,
        height,
        maxWidth,
        maxHeight
      )
      const extensionsDescription =
        this.returnsExtensionsDescription(extensions)

      description += '('

      if (dimensionsDescription && extensionsDescription) {
        description += `${extensionsDescription} | ${dimensionsDescription} | Tamanho máximo: <b>${maxFileSize}</b>`
      } else if (dimensionsDescription) {
        description += `${dimensionsDescription} | Tamanho máximo: <b>${maxFileSize}</b>`
      } else if (extensionsDescription) {
        description += `${extensionsDescription} | Tamanho máximo: <b>${maxFileSize}</b>`
      }

      if (cropWidth && cropHeight) {
        description += ` | Tamanho da imagem: ${cropWidth}x${cropHeight}`
      }

      if (recomendation) {
        description += ` | Recomendação: ${recomendation}`
      }

      description += ')'

      return description
    },

    uploaderInstance() {
      return this.$refs[this.id]
    },

    getApiUrl() {
      return process.env.VUE_APP_API_URL
    },

    getAuthorization() {
      const token = AuthService.getAuth()
      return token.Authorization
    }
  },

  watch: {
    'editFile.show'(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ''
        })
      }

      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }

          const cropper = new Cropper(this.$refs.editImage, {
            autoCrop: true,
            zoomOnWheel: false,
            cropBoxResizable: false,
            minCropBoxWidth: this.minCroppedWidth,
            minCropBoxHeight: this.minCroppedHeight,
            aspectRatio: this.minCroppedWidth / this.minCroppedHeight
          })

          this.editFile = {
            ...this.editFile,
            cropper
          }
        })
      }
    }
  },

  updated() {
    this.isUploadFull()
  },

  methods: {
    /**
     * Update file to edit
     */
    onEditFileShow(file) {
      this.editFile = { ...file, show: true }
      this.$refs[this.id].update(file, { error: 'edit' })
    },

    /**
     * Get method from lodash
     * @see https://lodash.com/docs/#get
     * @public
     */
    get,

    /**
     * Creates thumbs to file
     * @see https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Full.vue
     * @public
     */
    async inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter file extension
        if (!this.isValidExtension(newFile.name)) {
          this.notify('error', 'O tipo do arquivo é inválido!')
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }

        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob

          // does not validade dimensions if svg file
          if (newFile.type !== 'image/svg+xml') {
            // check dimensions
            const isImageValid = await this.imageHasValidDimensions(
              newFile.blob
            )
            if (!isImageValid) {
              this.$refs[this.id].remove(newFile)
              this.notify('error', 'As dimensões da imagem estão incorretas!')
            }
          }
        }
      }
    },

    /**
     * Validate files
     * @see https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Full.vue
     * @public
     */
    async inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          // Crop automatico
          if (
            this.minCroppedWidth &&
            this.minCroppedHeight &&
            newFile.type.includes('image')
          ) {
            const img = new Image()
            const promise = () =>
              new Promise(function (resolve) {
                img.onload = function () {
                  resolve(true)
                }
                img.src = newFile.blob
              })

            await promise()

            const canvas = document.createElement('canvas')
            const propCrop = this.minCroppedWidth / this.minCroppedHeight
            const propImage = img.width / img.height

            let width = img.width
            let height = img.height

            if (propImage > propCrop) {
              if (width > this.minCroppedWidth) {
                width *= this.minCroppedHeight / height
                height = this.minCroppedHeight
              }
            } else {
              if (height > this.minCroppedHeight) {
                height *= this.minCroppedWidth / width
                width = this.minCroppedWidth
              }
            }

            canvas.width = this.minCroppedWidth
            canvas.height = this.minCroppedHeight

            const outputX = (this.minCroppedWidth - width) * 0.5
            const outputY = (this.minCroppedHeight - height) * 0.5

            var ctx = canvas.getContext('2d')

            ctx.drawImage(img, outputX, outputY, width, height)

            const data = {
              name: newFile.name
            }

            const binStr = atob(canvas.toDataURL(newFile.type).split(',')[1])
            const arr = new Uint8Array(binStr.length)

            for (let i = 0; i < binStr.length; i++) {
              arr[i] = binStr.charCodeAt(i)
            }

            data.file = new File([arr], data.name, { type: newFile.type })
            data.size = data.file.size

            this.$refs[this.id].update(newFile, data)
          }
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.update(newFile, { error: 'size' })
          }
        }

        if (newFile.error && !oldFile.error) {
          var self = this
          // error
          // Erros possíveis size, extension, timeout, abort, network, server, denied
          switch (newFile.error) {
            case 'extension':
              self.notify('error', 'Extensão não permitida!')
              break
            case 'timeout':
              self.notify('error', 'O tempo de espera excedido!')
              break
            case 'abort':
              self.notify('error', 'Cancelado!')
              break
            case 'network':
              self.notify('error', 'Problemas na rede, verifique sua internet!')
              break
            case 'server':
              self.notify(
                'error',
                'O servidor não está respondendo, entre em contato com o desenvolvedor!'
              )
              break
            case 'denied':
              self.notify(
                'error',
                'Não foi possível realizar upload desse arquivo!'
              )
              break
          }
        }

        if (newFile.success && !oldFile.success) {
          const response = JSON.parse(newFile.xhr.response)

          if (this.maxFiles > this.medias.length) {
            this.medias.push(response)
          } else {
            this.notify('error', 'Você atingiu o limite máximo de arquivos!')
          }

          this.$refs[this.id].remove(newFile)
          this.$forceUpdate()
        }
      }

      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs[this.id].active) {
          this.$refs[this.id].active = true
        }
      }
    },

    /**
     * Format size value of file. Default file size is displayed in byte.
     * @public
     */
    formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      }

      if (size) {
        return size.toString() + ' B'
      }
    },

    /**
     * Validate dimensions of image
     * @public
     */
    async imageHasValidDimensions(fileBlob) {
      const img = new Image()
      let isValid = true
      const widthProp = this.width
      const heightProp = this.height
      const maxWidthProp = this.maxWidth
      const maxHeightProp = this.maxHeight

      const promise = () =>
        new Promise(function (resolve) {
          img.onload = function () {
            if (widthProp !== 0) {
              if (img.width !== widthProp) {
                isValid = false
              }
            }
            if (heightProp !== 0) {
              if (heightProp !== img.height) {
                isValid = false
              }
            }
            if (maxWidthProp !== 0) {
              if (maxWidthProp > img.width) {
                isValid = false
              }
            }
            if (maxHeightProp !== 0) {
              if (maxHeightProp > img.height) {
                isValid = false
              }
            }
            resolve(isValid)
          }

          img.src = fileBlob
        })

      await promise()

      return isValid
    },

    /**
     * Fire notification
     * @public
     */
    notify(type, text) {
      this.$notify({
        group: 'notifications',
        type,
        text
      })
    },

    /**
     * Validate if the uploaded file has a valide extension
     * @public
     */
    isValidExtension(fileName) {
      let isValid = true
      const fileExtension = fileName.split('.').pop()

      if (
        this.extensions &&
        !this.extensions.includes(fileExtension.toLowerCase())
      ) {
        isValid = false
      }

      return isValid
    },

    /**
     * Generates dimensions text description
     * @public
     */
    returnsDimensionsDescription(width, height, maxWidth, maxHeight) {
      let dimensionsDescription = ''

      if (height && width) {
        dimensionsDescription = `Dimensões: <b>${width}</b> x <b>${height}</b>`
      } else if (height && width) {
        dimensionsDescription = `Dimensões: <b>${width}</b> x <b>${height}</b>`
      } else if (height) {
        dimensionsDescription = `Altura: <b>${height}</b> pixels`
      } else if (width) {
        dimensionsDescription = `Largura: <b>${width}</b> pixels`
      } else if (maxWidth && maxHeight) {
        dimensionsDescription = `Dimensões máxima: <b>${maxWidth}</b> x <b>${maxHeight}</b>`
      } else if (maxWidth) {
        dimensionsDescription = `Largura máxima: <b>${maxWidth}</b>`
      } else if (maxHeight) {
        dimensionsDescription = `Altura máxima: <b>${maxHeight}</b>`
      }

      return dimensionsDescription
    },

    /**
     * Generates extensions text description
     * @public
     */
    returnsExtensionsDescription(extensions) {
      let extensionsDescriptions = ''

      if (extensions) {
        const newExtensions = extensions.replace(/,/g, '/').toUpperCase()

        extensionsDescriptions = `Formato: <b>${newExtensions}</b>`
      }

      return extensionsDescriptions
    },

    /**
     * Hides select file button when total
     * of files is equal to maxFiles prop
     * @public
     */
    isUploadFull() {
      if (
        this.enableMultiple &&
        this.maxFiles > this.medias.length + this.files.length
      ) {
        this.uploadFile = true
      } else if (
        !this.enableMultiple &&
        this.medias.length === 0 &&
        this.files.length === 0
      ) {
        this.uploadFile = true
      } else {
        this.uploadFile = false
      }
    },

    removeFile(isApi, file) {
      if (isApi) {
        const index = findIndex(this.medias, function (data) {
          return data.id === file.id
        })
        this.medias.splice(index, 1)
      } else {
        this.$refs[this.id].remove(file)
      }
    },

    saveEditmodal(event) {
      this.editModal.displayModal = event
      this.$emit('onEditClose')
    },

    updateFileInfo(event) {
      let mediaIndex = ''

      this.medias.map((media, index) => {
        if (event.id === media.id) {
          mediaIndex = index
        }
      })

      this.medias[mediaIndex].custom = event
    }
  }
}
</script>

<style lang="scss">
#fileuploader {
  .help-message {
    text-align: center;
    padding: 50px;
    font-size: 1.25rem;
    background-color: rgba(0, 0, 0, 0.015);
    border-radius: 0.25rem;
    border: 1px dashed #313c4f;
  }

  .uploaderInfo {
    color: $secondary-900;

    span {
      color: #768192 !important;
      font-size: 0.8em;
    }
  }

  .table-head {
    border: 1px solid rgba(0, 0, 21, 0.125);
  }

  .data-from-api-table {
    tr {
      display: table-row;
      width: 100%;

      &:hover {
        cursor: move;
      }
    }
  }

  .dataTable-actions {
    .action {
      display: inline-block;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: inherit;
    }
  }

  .edit-image {
    img {
      max-width: 100%;
    }
  }

  .table {
    td {
      vertical-align: middle !important;
      border-top: 0px !important;
    }

    .table-actions {
      .table-action {
        a {
          color: inherit;
        }
      }
    }
  }

  .cursor-pointer {
    label:hover {
      cursor: pointer;
    }
  }

  .thumb {
    object-fit: contain;
  }
}
</style>
